import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: 'Main',
        component: () => import('../views/Main.vue'),
        children: [
            
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/login.vue')

    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/login/register.vue')

    },
    {
        path: '/resetPwd',
        name: 'resetPwd',
        component: () => import('../views/login/resetPwd.vue')

    }
]

const router = new VueRouter({
    mode: 'hash',
    routes
})


export default router