import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'  // 组件通信

import {
  Button, Card, Container, Aside, Header, Main, Menu,
  Submenu, MenuItem, MenuItemGroup, Dropdown, DropdownMenu, DropdownItem,
  Row, Col, BreadcrumbItem, Breadcrumb, Tag, Form, FormItem,
  Input, Select, Switch, DatePicker, Option, Dialog, Table, TableColumn, Pagination,
  MessageBox, Message,Link 
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import http from 'axios';

Vue.config.productionTip = false
Vue.use(router)
Vue.use(Button)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Header)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Tag)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Switch)
Vue.use(DatePicker)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Link)


Vue.prototype.$http = http
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message

router.beforeEach((to, from, next) => {
  document.title = '久界风控系统-秒级精准实时风控'
  store.commit('getToken')
  const token = store.state.user.token
  if (!token && to.name !== 'login' && to.name !== 'register' && to.name !== 'resetPwd') {
    next({name: 'login' })
  // } else if (token && to.name === 'login') {
  //  let param = location.href
  //   if (param.indexOf('=') !== -1 && param.indexOf('outerUserId') !== -1) {
  //     next()
  //   }
  //   next({ name: 'home' })

  } else {
    next()
  }
})

new Vue({
  store,
  router,
  render: h => h(App),
  created(){
    store.commit('addMenu',this.$router)
  }
}).$mount('#app')
