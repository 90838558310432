import Cookie from 'js-cookie'

export default {
    state: {
        isCollapse: false,
        tabsList: [
            {
                path: '/',
                name: 'home',
                label: '首页',
                url: '/home'
            }
        ],
        currentMenu: null,
        menu: []
    },
    mutations: {
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse
        },
        selectMenu(state, val) {
            if (val.name !== 'home') {
                state.currentMenu = val;
                const result = state.tabsList.findIndex(item => item.name === val.name)
                if (result === -1) {
                    state.tabsList.push(val)
                }
            } else {
                state.currentMenu = null
            }
        },
        closeTag(state, val) {
            let result = state.tabsList.findIndex(item => item.name === val.name)
            state.tabsList.splice(result, 1)
        },
        setMenu(state, val) {
            state.menu = val
            localStorage.setItem('menu', JSON.stringify(val))
        },
        clearMenu(state) {
            state.menu = []
            localStorage.removeItem('menu')
        },
        addMenu(state, router) {
            if (!localStorage.getItem('menu') || localStorage.getItem('menu') === 'undefined') {
                return
            }
            const menu = JSON.parse(localStorage.getItem('menu'))
            state.menu = menu
            const menuArray = []
            menu.forEach(item1 => {
                if (item1.children) {
                    item1.children = item1.children.map(item2 => {
                        item2.component = () => import(`../views/${item1.name}/${item2.name}`)
                        return item2
                    })
              
                    menuArray.push(...item1.children)
                } else {
                    item1.component = () => import(`../views/${item1.name}`)
                    menuArray.push(item1)
                }
            });
            // 路由的动态添加
            menuArray.forEach(item => {
                router.addRoute('Main',item)
            })
        },
       
    }
}